// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-beneficios-page-js": () => import("./../src/pages/beneficiosPage.js" /* webpackChunkName: "component---src-pages-beneficios-page-js" */),
  "component---src-pages-blog-page-js": () => import("./../src/pages/blogPage.js" /* webpackChunkName: "component---src-pages-blog-page-js" */),
  "component---src-pages-fqa-page-js": () => import("./../src/pages/fqaPage.js" /* webpackChunkName: "component---src-pages-fqa-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-puntos-dv-js": () => import("./../src/pages/puntosDV.js" /* webpackChunkName: "component---src-pages-puntos-dv-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

